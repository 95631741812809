import React from 'react';
import './Prices.css';
import { Trans, useTranslation } from 'react-i18next';
import MainLayout from '../MainLayout/MainLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HashLink as Link } from 'react-router-hash-link';
import Divider from '@mui/material/Divider';

function Prices() {
    const { t } = useTranslation();

    return (
        <MainLayout>
            <h1>{t("prices")}</h1>
            <TableContainer className='priceTable' component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("room")}</TableCell>
                            <TableCell align="right">{t("price_night")}</TableCell>
                            <TableCell align="right">{t("extra_bed")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className='tableRow' key="heure-bleue" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                <Link className='room-link' to="/rooms#room-blue">{t("heure_bleue")}</Link>
                            </TableCell>
                            <TableCell align="right">100€</TableCell>
                            <TableCell align="right">{t("not_available")}</TableCell>
                        </TableRow>
                        <TableRow className='tableRow' key="air-temps" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                <Link className='room-link' to="/rooms#room-time">{t("air_temps")}</Link>
                            </TableCell>
                            <TableCell align="right">100€</TableCell>
                            <TableCell align="right">20€</TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <p className="breakfast">{t("includes_breakfast")}</p>
            <Divider />
            <h2>{t("booking_conditions")}</h2>
            <ul>
                <li className='conditions'><Trans i18nKey="accepted_payment">Paiements acceptés : <span className='bold'>espèces</span>.</Trans></li>
                <li className='conditions'><Trans i18nKey="check_in"><span className='bold'>Les arrivées se font de 17:00 à 20:00.</span> Possibilité d'arriver plus tôt ou plus tard sur demande.</Trans></li>
                <li className='conditions'><Trans i18nKey="check_out"><span className='bold'>Départ : 11 heures.</span> Vous pourrez bien sûr laisser vos bagages pour la journée ou profiter du salon en attendant votre départ.</Trans></li>
                <li className='conditions'><Trans i18nKey="non_smoking">Le Jardin Clos est entièrement <span className='bold'>non-fumeur</span>. Il est possible de fumer dans le jardin.</Trans></li>
                <li className='conditions'><span className='bold'>{t("no_animals")}</span></li>
            </ul>
        </MainLayout>
    );
}

export default Prices;
