import React from 'react';
import './Gallery.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { maxHeight } from '@mui/system';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

function Gallery(props) {
    const items = props.images;

    const [open, setOpen] = React.useState(false);
    const [index, setIndex] = React.useState(false);

    function srcset(image, size, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    function handleOpen(title) {
        let index = 0
        for (const [idx, element] of items.entries()) {
            if (title === element.title) {
                index = idx;
                break;
            }
        }
        setOpen(true);
        setIndex(index);
    }

    return (
        <div>
            <Lightbox
                open={open}
                index={index}
                close={() => setOpen(false)}
                slides={
                    items.map((item) => (
                        { src: item.img, title: item.title }
                    ))
                }
                plugins={[Captions, Zoom, Fullscreen]}
            />
            <Box className='center' sx={{ display: { xs: 'none', md: 'flex' } }}>
                <ImageList sx={{ width: 800, height: maxHeight }} cols={3} rowHeight={200}>
                    {items.map((item) => (
                        <ImageListItem className='imageGallery' onClick={() => handleOpen(item.title)} key={item.img}>
                            <img {...srcset(item.img, 164)} alt={item.title} loading="lazy" />
                            <ImageListItemBar title={item.title} />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
            <Box className='center' sx={{ display: { xs: 'flex', md: 'none' } }}>
                <ImageList sx={{ width: 500, height: maxHeight }} cols={2} rowHeight={200}>
                    {items.map((item) => (
                        <ImageListItem className='imageGallery' onClick={() => handleOpen(item.title)} key={item.img}>
                            <img {...srcset(item.img, 164)} alt={item.title} loading="lazy" />
                            <ImageListItemBar title={item.title} />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </div>
    );
}

export default Gallery;