import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "jardin_clos": "Le Jardin Clos",
            "welcome": "Welcome to the Jardin Clos.",
            "description": "In the heart of a charming village of Lauragais, Hélène and Christophe welcome you in their guest house where you will find a nice atmosphere, suitable to calm and quietness.",
            "description_2": "This accommodation is located in the restored buildings of an old house at 40 minutes south of Toulouse and 1 hour of Carcassonne.\n\nThe house is a perfect place to relax and to have a break.\n\nWe are offering two spacious, quiet and comfortable rooms.",
            "reviews": "Reviews",
            "review_1": "Little piece of heaven.",
            "review_2": "I highly recommend this guest house.",
            "review_3": "Many thanks to the hosts. We had an excellent stay. To the pleasure!",
            "home": "Home",
            "rooms": "Rooms",
            "surroundings": "Surroundings",
            "prices": "Prices",
            "contact": "Contact",
            "room-blue": "Room l'Heure Bleue",
            "room-time": "Room l'Air du Temps",
            "room": "Room",
            "price_night": "Price for a night",
            "extra_bed": "Extra bed",
            "heure_bleue": "L'Heure Bleue",
            "air_temps": "L'Air du Temps",
            "not_available": "Not available",
            "includes_breakfast": "Prices include breakfast.",
            "Contact Us": "Contact Us",
            "Email": "Email:",
            "Phone": "Phone:",
            "Address": "Address:",
            "Access": "Access",
            "GPS": "GPS coordinates",
            "Latitude": "Latitude:",
            "Longitude": "Longitude:",
            "booking_conditions": "Booking conditions",
            "accepted_payment": "Accepted payment methods: <1>cash</1>.",
            "check_in": "<0>Check-in between 17h and 20h.</0> Possibility to arrive earlier or later on request.",
            "check_out": "<0>Check-out: 11AM.</0> You can of course leave your luggage for the day or enjoy the lounge waiting for your departure.",
            "non_smoking": "The Jardin Clos is completely <1>non-smoking</1>. It is possible to smoke in the garden.",
            "no_animals": "Pets are not allowed.",
            "toulouse": "Toulouse, the pink city",
            "surroundings_intro": "Close to our guest house, you can find beautiful places to visit in the surrounding area. Caujac is located near Toulouse, Carcassonne and Auterive.",
            "surroundings_toulouse": "Toulouse is the capital city of the southwestern French department of Haute-Garonne, as well as of the Occitanie region. It is the fourth-largest city in France.\n\nToulouse is the centre of the European aerospace industry, with the headquarters of Airbus.\n\nStade Toulousain of the Top 14 is considered one of the most successful rugby union clubs in all of Europe, having been crowned the Heineken Cup champions four times.\n\nA city with unique architecture made of pinkish terracotta bricks, which earned it the nickname la Ville Rose (“the Pink City”), Toulouse counts two UNESCO World Heritage Sites, the Canal du Midi (designated in 1996 and shared with other cities), and the Basilica of St. Sernin, the largest remaining Romanesque building in Europe, designated in 1998 because of its significance to the Santiago de Compostela pilgrimage route.\n\nThe city's gastronomic specialties include the Saucisse de Toulouse, a type of sausage, cassoulet Toulousain, a bean and pork stew, and garbure, a cabbage soup with poultry. Also, foie gras, the liver of an overfed duck or goose, is a delicacy mainly made in the Midi-Pyrénées.",
            "canal": "The canal du midi",
            "surroundings_canal": "The canal du Midi is a canal that connects the Garonne River to the Étang de Thau on the Mediterranean and along with the 193 km long Canal de Garonne forms the Canal des Deux Mers joining the Atlantic to the Mediterranean.\n\nIt is considered  to be one of the greatest construction works of the 17th century.\n\nIt was inscribed as a UNESCO World Heritage Site in 1996.",
            "carcassonne": "Carcassonne",
            "surroundings_carcassonne": "Carcassonne is a fortified French town in the Aude department.\n\nThe city is famous for the Cité de Carcassonne, a medieval fortress restored by the theorist and architect Eugène Viollet-le-Duc in 1853 and added to the UNESCO list of World Heritage Sites in 1997. Consequently, Carcassonne greatly profits from tourism but also counts manufacture and wine-making as some of its other key economic sectors.",
            "house": "The house",
            "room-blue-description": "Located upstairs, you will access your room via a little dressing where you can put your suitcases and hang your clothes.\n\nA 25m² room with a private bathroom and a little lounge. A bed of 160cm, solid oak floor.\n\nA bathroom of 9m² equipped with a large sink, a large shower and a toilet. Hairdryer, towels and bath robes are provided.",
            "room-time-description": "Grey and white tones for this 22m² room located upstairs, under the roof, which calls for a romantic break.\n\nDressing area to put bags and coats.\n\nA bed of 160cm. Solid oak floor and exposed beams.\n\nDirect access to a 9m² bathroom with a belle époque washbasin and bathtub, and a toilet. Hairdryer, bathrobes and towels are provided.\n\nAccess to an extra room with two 90cm beds (kids and teenagers).",
            "lounge": "Lounge",
            "lounge_description": "A large 35m lounge is accessible to everyone. You can relax on a large sofa with chaise longue, watching TV on a 140cm screen with Canal+, listen to music, or play on a Bonzini babyfoot.\n\nCement floor tiles and white painted wooden ceiling complete the feeling of comfort.\n\nBesides, one will find a common kitchen of 35m² where breakfast will be served. Spacious and bright, it has a large open fireplace, craft furniture diverted and a cement floor tiles.",
            "garden": "Garden",
            "garden_description": "Closed garden of 1500m² with many trees. There are many plantations: a century-old cedar, cypresses, fig trees, palm trees, roses…\n\nThere is also a salt water swimming pool 10 meters by 3 meters with a depth of 1.50m with concrete steps.\n\nIt is secured by an alarm which answers safety standards. Water control regularly checked by us.\n\nWooden sunloungers with mattresses are available.\n\nThe pool is open from mid-May to September, weather permitting.\n\nYou will also find a 60m² barn with a table which can be used to serve breakfast, sofas and a tennis table.\n\nDo not forget your swimsuits. The towels are provided.",
        }
    },
    fr: {
        translation: {
            "jardin_clos": "Le Jardin Clos",
            "welcome": "Bienvenue dans la chambre d'hôtes, le Jardin Clos.",
            "description": "Au cœur d'un charmant village du Lauragais du nom de Caujac, tout près d'Auterive et de Toulouse, Hélène et Christophe vous ouvrent les portes de leur maison d'hôtes et vous accueillent chaleureusement dans une ambiance raffinée, propice au calme et à la détente.",
            "description_2": "Cet hébergement occupe les dépendances restaurées d'une maison de maître à 40 minutes au Sud de Toulouse et 1 heure de Carcassonne.\n\nLa maison est une vraie incitation à la détente et au ressourcement, une adresse idéale pour s'aérer l'esprit.\n\nNous vous proposons deux chambres spacieuses, calmes et tout confort.",
            "reviews": "Notes de nos clients",
            "review_1": "Petit coin de paradis.",
            "review_2": "Je recommande vivement cette maison d'hôtes.",
            "review_3": "Un grand merci aux hôtes. Nous avons passé un excellent séjour. Au plaisir !",
            "home": "Accueil",
            "rooms": "Les chambres",
            "surroundings": "Alentours",
            "prices": "Tarifs",
            "contact": "Contact",
            "room-blue": "Chambre l'Heure Bleue",
            "room-time": "Chambre l'Air du Temps",
            "room": "Chambre",
            "price_night": "Prix par nuit",
            "extra_bed": "Lit supplémentaire",
            "heure_bleue": "L'Heure Bleue",
            "air_temps": "L'Air du Temps",
            "not_available": "Non disponible",
            "includes_breakfast": "Tarifs des chambres avec petit déjeuner compris.",
            "Contact Us": "Nous contacter",
            "Email": "Email :",
            "Phone": "Mobile :",
            "Address": "Adresse :",
            "Access": "Accès",
            "GPS": "Coordonnées GPS",
            "Latitude": "Latitude :",
            "Longitude": "Longitude :",
            "booking_conditions": "Conditions de réservation",
            "accepted_payment": "Paiements acceptés : <1>espèces</1>.",
            "check_in": "<0>Les arrivées se font de 17:00 à 20:00.</0> Possibilité d'arriver plus tôt ou plus tard sur demande.",
            "check_out": "<0>Départ : 11 heures.</0> Vous pourrez bien sur laisser vos bagages pour la journée ou profiter du salon en attendant votre départ.",
            "non_smoking": "Le Jardin Clos est entièrement <1>non-fumeur</1>. Il est possible de fumer dans le jardin.",
            "no_animals": "Nos amis les animaux ne sont pas admis.",
            "toulouse": "Toulouse, la ville rose",
            "surroundings_intro": "Près de notre maison d'hôtes, vous pourrez trouver aux alentours de magnifiques endroits à visiter. Caujac est situé près de Toulouse, de Carcassonne et d'Auterive.",
            "surroundings_toulouse": "Toulouse est une commune du Sud-Ouest et du Midi de la France.\n\nVille à l'architecture caractéristique des cités du Midi de la France, Toulouse est surnommée la « ville rose » en raison de la couleur du matériau de construction traditionnel local, la brique de terre cuite. Le développement de la culture de la violette de Toulouse au xixe siècle en fait un emblème de la ville et lui vaut le surnom de « cité des violettes ».\n\nToulouse est la capitale européenne de l'industrie aéronautique et spatiale avec les sites d'Airbus.\n\nLe sport emblématique de Toulouse est le rugby à XV, son club du Stade toulousain détenant le plus riche palmarès sur le plan national comme sur le plan continental.\n\nLe cassoulet et la saucisse sont les spécialités emblématiques de la gastronomie toulousaine.",
            "canal": "Le canal du midi",
            "surroundings_canal": "Le canal du Midi est un canal français qui relie la Garonne à la mer Méditerranée.\n\nReliant Toulouse à Sète, le canal du Midi est classé au Patrimoine mondial de l'Unesco depuis 1996.",
            "carcassonne": "Carcassonne",
            "surroundings_carcassonne": "La Cité de Carcassonne est un ensemble architectural médiéval qui se trouve dans la ville française de Carcassonne dans le département de l'Aude, région du Languedoc-Roussillon-Midi-Pyrénées. Cette cité médiévale fortifiée, dont les origines remontent à la période gallo-romaine, doit sa renommée à sa double enceinte, atteignant près de 3 km de longueur et comportant cinquante-deux tours, qui domine de manière spectaculaire la vallée de l'Aude. La Cité comprend également un château (le château comtal) et une basilique (la basilique Saint-Nazaire).\n\nLa Cité de Carcassonne est, depuis 1997, classée au patrimoine mondial de l'UNESCO.",
            "house": "La maison",
            "room-blue-description": "Située à l'étage, vous pénétrerez par un petit dressing où vous pourrez poser vos valises et suspendre vos vêtements.\n\nChambre de 25m² avec salle d'eau privative et petit salon. Grand lit en 160cm avec deux sommiers indépendants, plancher en chêne massif.\n\nSalle de bain de 9m² équipée d'une grande vasque, un WC et une douche à l'italienne. Sont mis à disposition un sèche-cheveux, serviettes et peignoirs de bain.",
            "room-time-description": "Tonalité de gris et de blanc pour cette chambre de 22m² située à l'étage, mansardée, qui invite à une pause romantique.\n\nCoin dressing pour poser valises et manteaux.\n\nGrand lit en 160 cm avec deux sommiers indépendants. Plancher chêne massif et poutre apparente.\n\nAccès direct à une salle de bain de 9m² avec lavabo belle époque, WC et baignoire ancienne en céramique. Sèche-cheveux, peignoirs et serviettes sont mis à disposition.\n\nAccès à une petite chambre supplémentaire avec deux lits en 90 cm (enfants et adolescents).",
            "lounge": "Salon",
            "lounge_description": "Un grand salon de 35m² est accessible à tous. On peut se détendre sur un grand canapé avec méridienne, regarder la TV sur un écran de 140cm avec Canal+, écouter de la musique, ou faire des parties sur un baby-foot Bonzini.\n\nSol en carreaux de ciment et plafond en bois peint en blanc complètent la sensation de confort.\n\nA côté, on trouvera une cuisine commune de 35m² où seront servis les petits-déjeuners. Spacieuse et lumineuse, elle possède une vaste cheminée ouverte, des meubles de métier détournés et un sol en carreaux de ciment.",
            "garden": "Jardin",
            "garden_description": "Jardin de 1500m² clos et arboré. On y trouve diverses plantations : un cèdre centenaire, des cyprès, des figuiers, des palmiers, des rosiers…\n\nOn y trouve également une piscine au sel de 10 mètres par 3 mètres de style vieux bassin en béton projeté gris souris d'une profondeur d'1m50 avec marches en béton.\n\nElle est sécurisée par une alarme répondant aux normes de sécurité. Le contrôle de l'eau est effectué régulièrement par nos soins.\n\nDes bains de soleil en bois avec matelas sont à disposition.\n\nLa piscine est ouverte de mi-mai à septembre selon la météo.\n\nOn trouvera également une grange de 60m² comprenant une table où pourront être servis les petits déjeuners, des canapés et une table de ping-pong.\n\nN'oubliez pas vos maillots. Les serviettes de bain sont fournies.",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;