import './Home.css';
import { useTranslation } from 'react-i18next';
import MainLayout from '../MainLayout/MainLayout';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Gallery from '../Gallery/Gallery';

function Home() {
  const { t } = useTranslation();

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const itemDataHome = [
    {
      img: require('../images/home/home-1.jpg'),
      title: 'Jardin',
    },
    {
      img: require('../images/home/home-2.jpg'),
      title: 'Ange',
    },
    {
      img: require('../images/home/home-3.jpg'),
      title: 'Salon et baby-foot',
    },
    {
      img: require('../images/home/home-4.jpg'),
      title: 'Extérieur piscine',
    },
    {
      img: require('../images/home/home-5.jpg'),
      title: 'Extérieur devanture',
    },
    {
      img: require('../images/home/home-6.jpg'),
      title: 'Extérieur canapé',
    },
  ]

  return (
    <MainLayout>
      <div className="Home">
        <h1 className='siteTitle'>{t("jardin_clos")}</h1>
        <h2>{t("welcome")}</h2>
        <p className='left'>{t("description")}</p>
        <p className='left allow-breaklines'>{t("description_2")}</p>
      </div>
      <Gallery images={itemDataHome} />
      <Divider />
      <div>
        <h2>{t("reviews")}</h2>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Le+Jardin+Clos/@43.298872,1.4654126,17z/data=!4m10!3m9!1s0x12aedce987dee14d:0x6922e0c09f55f4ac!5m2!4m1!1i2!8m2!3d43.2988747!4d1.4675896!9m1!1b1">
          <Rating name="read-only" value={5} readOnly />
        </a>
        <CardHeader
          avatar={<Avatar alt="Carola Garnier" {...stringAvatar('Carola Garnier')} />}
          title={'"' + t("review_1") + '"'}
        />
        <CardHeader
          avatar={<Avatar alt="Corinne Fourel" {...stringAvatar('Corinne Fourel')} />}
          title={'"' + t("review_2") + '"'}
        />
        <CardHeader
          avatar={<Avatar alt="Karine Lacoste" {...stringAvatar('Karine Lacoste')} />}
          title={'"' + t("review_3") + '"'}
        />
      </div>
    </MainLayout>
  );
}

export default Home;
