import './MainLayout.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import backgroundImage from '../images/bg.jpg';


class MainLayout extends Component {
    render() {
        return (
            <Box className="mainBody" style={{ backgroundImage: `url(${backgroundImage})` }} sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Header />
                    </Grid>
                    <Grid className="content" xs={12}>
                        {this.props.children}
                    </Grid>
                    <Grid xs={12}>
                        <Footer />
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default MainLayout;