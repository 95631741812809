import React from 'react';
import './Surroundings.css';
import MainLayout from '../MainLayout/MainLayout';
import { useTranslation } from 'react-i18next';

function Surroundings() {
    const { t } = useTranslation();

    return (
        <MainLayout>
            <h1>{t("surroundings")}</h1>
            <p className="left">{t("surroundings_intro")}</p>
            <h2>{t("toulouse")}</h2>
            <p className='left allow-breaklines'>{t("surroundings_toulouse")}</p>
            <figure>
                <img className='w100' src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Toulouse_Capitole_Night_Wikimedia_Commons.jpg" alt="Toulouse" />
                <figcaption>Place du Capitole - Toulouse</figcaption>
            </figure>

            <h2>{t("canal")}</h2>
            <p className='left allow-breaklines'>{t("surroundings_canal")}</p>
            <figure>
                <img className='w100' src="https://frankrijkpuur.nl/wp-content/uploads/2015/06/parcourir-le-canal-du-midi.jpg" alt="Le canal du midi" />
                <figcaption>Canal du midi</figcaption>
            </figure>

            <h2>{t("carcassonne")}</h2>
            <p className='left allow-breaklines'>{t("surroundings_carcassonne")}</p>
            <figure>
                <img className='w100' src="https://3.bp.blogspot.com/-xlU_Y7Ydb4g/V4ZeWJADagI/AAAAAAAABI0/D8YOrHuS-uA6_4fES2R2LzVuG1dWbQKpwCLcB/s1600/carcassonne-franc%25CC%25A7a.jpg" alt="Carcassonne" />
                <figcaption>Cité de Carcassonne</figcaption>
            </figure>
        </MainLayout >
    );
}

export default Surroundings;
