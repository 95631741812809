import React from 'react';
import './Rooms.css'
import MainLayout from '../MainLayout/MainLayout';
import Gallery from '../Gallery/Gallery';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

function Rooms() {
    const { t } = useTranslation();

    const itemDataBlue = [
        {
            img: require('../images/rooms/room-blue-1.jpg'),
            title: 'Vue lit chambre',
        },
        {
            img: require('../images/rooms/room-blue-2.jpg'),
            title: 'Vue chambre',
        },
        {
            img: require('../images/rooms/room-blue-3.jpg'),
            title: 'Vue bureau',
        },
        {
            img: require('../images/rooms/room-blue-4.jpg'),
            title: 'Vue salle de bain',
        },
        {
            img: require('../images/rooms/room-blue-5.jpg'),
            title: 'Vue salle de bain 2',
        },
        {
            img: require('../images/rooms/room-blue-6.jpg'),
            title: 'Vue lavabo',
        },
    ]

    const itemDataTime = [
        {
            img: require('../images/rooms/room-time-1.jpg'),
            title: 'Vue lit',
        },
        {
            img: require('../images/rooms/room-time-2.jpg'),
            title: 'Vue lit 2',
        },
        {
            img: require('../images/rooms/room-time-3.jpg'),
            title: 'Vue bureau',
        },
        {
            img: require('../images/rooms/room-time-4.jpg'),
            title: 'Vue canapé',
        },
        {
            img: require('../images/rooms/room-time-5.jpg'),
            title: 'Vue salle de bain',
        },
        {
            img: require('../images/rooms/room-time-6.jpg'),
            title: 'Vue baignoire',
        },
    ]

    return (
        <MainLayout>
            <h1>{t("rooms")}</h1>
            <h2 id='room-blue'>{t("room-blue")}</h2>
            <p className='left allow-breaklines'>{t("room-blue-description")}</p>
            <Gallery images={itemDataBlue} />
            <Divider />
            <h2 id='room-time'>{t("room-time")}</h2>
            <p className='left allow-breaklines'>{t("room-time-description")}</p>
            <Gallery images={itemDataTime} />
        </MainLayout>
    );
}

export default Rooms;
