import React from 'react';
import './Contact.css';
import { useTranslation } from 'react-i18next';
import MainLayout from '../MainLayout/MainLayout';
import Divider from '@mui/material/Divider';

function Contact() {
    const { t } = useTranslation();

    return (
        <MainLayout>
            <h1>{t("Contact Us")}</h1>
            <p><span className='bold'>{t("Email")}</span> <a className='mail' href="mailto:jardinclos31@gmail.com">jardinclos31@gmail.com</a></p>
            <p><span className='bold'>{t("Phone")}</span> +33 (0)7 68 67 96 78</p>
            <p><span className='bold'>{t("Address")}</span> 1, Rue de la iero - 31190 Caujac - FRANCE</p>
            <Divider />
            <h2>{t("Access")}</h2>
            <iframe id='openstreetmap' title="location" width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=1.4663743972778323%2C43.29850241256561%2C1.468627452850342%2C43.29969122176334&amp;layer=mapnik&amp;marker=43.29884500282183%2C1.4676028490066528"></iframe>
            <h2>{t("GPS")}</h2>
            <ul>
                <li>
                    <strong>{t("Latitude")}</strong> 43.298990
                </li>
                <li>
                    <strong>{t("Longitude")}</strong> 1.467906
                </li>
            </ul>
        </MainLayout>
    );
}

export default Contact;
