import React from 'react';
import MainLayout from '../MainLayout/MainLayout';
import Gallery from '../Gallery/Gallery';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

function House() {
    const { t } = useTranslation();

    const itemDataLounge = [
        {
            img: require('../images/house/lounge-1.jpg'),
            title: 'Salon - Canapé',
        },
        {
            img: require('../images/house/lounge-2.jpg'),
            title: 'Salon - Télévision',
        },
        {
            img: require('../images/house/lounge-3.jpg'),
            title: 'Salon - Baby-foot',
        },
        {
            img: require('../images/house/lounge-4.jpg'),
            title: 'Coin bureau',
        },
        {
            img: require('../images/house/lounge-5.jpg'),
            title: 'Coin bureau 2',
        },
        {
            img: require('../images/house/lounge-6.jpg'),
            title: 'Baby-foot',
        },
        {
            img: require('../images/house/lounge-7.jpeg'),
            title: 'Cuisine - Petit-déjeuner',
        },
    ]

    const itemDataGarden = [
        {
            img: require('../images/house/garden-1.jpg'),
            title: 'Piscine',
        },
        {
            img: require('../images/house/garden-2.jpg'),
            title: 'Hamac',
        },
        {
            img: require('../images/house/garden-3.jpg'),
            title: 'Fleurs',
        },
        {
            img: require('../images/house/garden-4.jpg'),
            title: 'Entrée',
        },
        {
            img: require('../images/house/garden-5.jpg'),
            title: 'Puits',
        },
        {
            img: require('../images/house/garden-6.jpg'),
            title: 'Maison',
        },
    ]

    return (
        <MainLayout>
            <h1>{t("house")}</h1>
            <h2>{t("lounge")}</h2>
            <p className='left allow-breaklines'>{t("lounge_description")}</p>
            <Gallery images={itemDataLounge} />
            <Divider />
            <h2>{t("garden")}</h2>
            <p className='left allow-breaklines'>{t("garden_description")}</p>
            <Gallery images={itemDataGarden} />
        </MainLayout>
    );
}

export default House;
