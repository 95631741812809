import './Footer.css';


function Footer() {

    const year = new Date().getFullYear()

    return (
        <div className="Footer">
            <footer>
                <p>Jardin Clos | © {year}</p>
            </footer>
        </div>
    );
}

export default Footer;
