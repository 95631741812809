import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Prices from './Prices/Prices';
import Surroundings from './Surroundings/Surroundings';
import Rooms from './Rooms/Rooms';
import House from './House/House';
import ErrorPage from "./ErrorPage/ErrorPage";
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './fonts/PinyonScript-Regular.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/prices",
    element: <Prices />,
  },
  {
    path: "/surroundings",
    element: <Surroundings />,
  },
  {
    path: "/rooms",
    element: <Rooms />,
  },
  {
    path: "/house",
    element: <House />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
