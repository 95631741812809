import React from 'react';
import './Header.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';


function Header() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const changeLanguage = (lng) => {
        setLanguage(lng);
        i18n.changeLanguage(lng);
    };

    const lang_dict = {
        "fr": {
            "opposite": "en",
            "switch": "🇺🇸 / 🇬🇧",
        },
        "en": {
            "opposite": "fr",
            "switch": "\u00A0\u00A0\u00A0\u00A0🇫🇷\u00A0\u00A0\u00A0\u00A0",
        },
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (

        <div className="Header">
            <header>
                <nav>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <NavLink className="link" to="/">{t('home')}</NavLink>
                        <NavLink className="link" to="/house">{t('house')}</NavLink>
                        <NavLink className="link" to="/rooms">{t('rooms')}</NavLink>
                        <NavLink className="link" to="/surroundings">{t('surroundings')}</NavLink>
                        <NavLink className="link" to="/prices">{t('prices')}</NavLink>
                        <NavLink className="link" to="/contact">{t('contact')}</NavLink>
                        <button className='langButton' onClick={() => changeLanguage(lang_dict[language]["opposite"])}>{lang_dict[language]["switch"]}</button>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem key="home" component={NavLink} to="/" onClick={handleCloseNavMenu}>
                                <span className="link">{t('home')}</span>
                            </MenuItem>
                            <MenuItem key="house" component={NavLink} to="/house" onClick={handleCloseNavMenu}>
                                <span className="link">{t('house')}</span>
                            </MenuItem>
                            <MenuItem key="rooms" component={NavLink} to="/rooms" onClick={handleCloseNavMenu}>
                                <span className="link">{t('rooms')}</span>
                            </MenuItem>
                            <MenuItem key="surroundings" component={NavLink} to="/surroundings" onClick={handleCloseNavMenu}>
                                <span className="link">{t('surroundings')}</span>
                            </MenuItem>
                            <MenuItem key="prices" component={NavLink} to="/prices" onClick={handleCloseNavMenu}>
                                <span className="link">{t('prices')}</span>
                            </MenuItem>
                            <MenuItem key="contact" component={NavLink} to="/contact" onClick={handleCloseNavMenu}>
                                <span className="link">{t('contact')}</span>
                            </MenuItem>
                        </Menu>
                        <button className='langButton' onClick={() => changeLanguage(lang_dict[language]["opposite"])}>{lang_dict[language]["switch"]}</button>
                    </Box>
                </nav>
            </header>
        </div>
    );
}

export default Header;
